/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import Link from 'components/ui/link';

import img1 from 'img/help/how-do-the-funnels-work/filters.png';
import img2 from 'img/help/how-do-the-funnels-work/funnels1.png';
import img3 from 'img/help/how-do-the-funnels-work/funnels2.png';
import img4 from 'img/help/how-do-the-funnels-work/funnels3.png';
import img5 from 'img/help/how-do-the-funnels-work/funnels4.png';
import img6 from 'img/help/how-do-the-funnels-work/funnels5.png';
import img7 from 'img/help/how-do-the-funnels-work/funnels6.png';
import img8 from 'img/help/how-do-the-funnels-work/metrics.png';
import img9 from 'img/help/how-do-the-funnels-work/steps.png';
import img10 from 'img/help/how-do-the-funnels-work/edit.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What are Funnels?</SubTitle>
      <p>
        A funnel (or a conversion funnel) is a set of actions your user should take visiting your
        website. Each funnel ends with a goal specific to the industry you’re in. For example, if
        you run an eCommerce store, your funnel should end with a purchase. For SaaS, it would be
        subscribing to one of your plans. As an independent consultant, you probably want your
        potential customers to schedule a meeting or at least leave their phone number and email
        address.
      </p>

      <p>
        Goals may also be specific to the objective you’d like to achieve. For example, if you are
        after getting positive reviews, you would probably start with (1) Showing an NPS, (2) Ask
        promoters to leave feedback, and (3) Gather their feedback through filling a form (which
        will, by the way, be your goal in this three-step funnel).
      </p>

      <SubTitle>How to build a funnel</SubTitle>

      <p>
        Building a funnel always starts with the end. Think of all measurable effects of your
        visitor’s journey through your website. Write them down. Analyze data from other sources and
        consider what conditions must be met, so your customer reaches the goal. Going down the
        funnel may require visiting a particular set of pages (for eCommerce: Homepage &gt; Category
        Page &gt; Product page &gt; Checkout page &gt; Order confirmation page) or meeting specific
        requirements. If you’re into the SaaS business and want to track switching to higher plans,
        your funnel may look like this: Visit billing page &gt; Click on the “Upgrade Plan” button
        &gt; Mark a checkbox accepting new terms &gt; Making a payment.
      </p>

      <p>
        In LiveSession, you can use any of our filters (including{' '}
        <Link href="/help/how-do-the-custom-events-work/">Custom Events</Link> and Custom
        Properties) to create a funnel.
      </p>
      <Image src={img1} lazy alt="Filters" title="LiveSession filters" />
      <SubTitle>Building a funnel in LiveSession</SubTitle>
      <p>
        Building funnels in LiveSession is straightforward. First, you have to click on the
        “Funnels” tab in the main menu on the left side of the screen:
      </p>
      <Image src={img2} lazy alt="LiveSession funnels" title="Click on Funnels in left menu" />
      <p>Then, click on the “New funnel”:</p>
      <Image src={img3} lazy alt="LiveSession funnels" title="Click on New funnel" />
      <p>And “Add step”:</p>
      <Image src={img4} lazy alt="LiveSession funnels" title="Add new steps" />
      <p>Create the funnel you designed and click the “Save” button:</p>
      <Image src={img5} lazy alt="LiveSession funnels" title="Click on Save button" />
      <SubTitle>How to access the funnels I built?</SubTitle>
      <p>All of your funnels will be available on the left panel:</p>
      <Image src={img6} lazy alt="LiveSession funnels" title="All funnels" />
      <p>You can watch sessions of users who reached a specific step in your funnel:</p>
      <Image src={img7} lazy alt="LiveSession funnels" title="Change funnel step" />
      <SubTitle>How to filter the results?</SubTitle>
      <p>You can also filter the results by specifying a date range and a Segment:</p>
      <Image src={img8} lazy alt="LiveSession funnels" title="Metrics" />
      <SubTitle>How to edit or delete a funnel?</SubTitle>
      <p>
        In order to edit or delete a funnel, use the buttons placed on the upper right corner of the
        screen:
      </p>
      <Image src={img10} lazy alt="LiveSession funnels" title="Edit your funnel steps" />
      <SubTitle>Can I add another step to a funnel?</SubTitle>
      <p>
        Yes, you can add other steps to your funnels. Click on the “Edit” button, add additional
        steps and click “Save”.
      </p>
      <SubTitle>How can I duplicate a funnel?</SubTitle>
      <p>
        If you want to create a copy of your funnel, click on the “Edit” button and “Save as…” -
        this way you will be able to save it as a new funnel.
      </p>
      <Image src={img9} lazy alt="LiveSession funnels" title="Funnel steps" />
      <p>
        If you want to try Funnels,{' '}
        <Link signUp sourceID="article">
          start a free 14-day trial
        </Link>{' '}
        or{' '}
        <Link href="https://app.livesession.io/signin" target="_blank" rel="noopener noreferrer">
          log in to your account
        </Link>
        .
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script/',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-check-if-my-tracking-script-works/',
    title: 'How to check if my tracking script works',
    description: 'Check if your tracking code is installed correctly',
  },
];

const Wrapped = HelpArticle(Content);

export const frontmatter = {
  metaTitle: 'How do the Funnels work?',
  metaDescription:
    'Learn what a funnel is and know how to use LiveSession Funnels to make your UX research more structured.',
  canonical: '/help/how-do-the-funnels-work/',
};

export default () => (
  <Wrapped title={frontmatter.metaTitle} related={related} section="Product" {...frontmatter} />
);
